import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pistols (weighted if possible) 5×2/leg`}</p>
    <p>{`DB Stiff Leg Deadlifts 5×6`}</p>
    <p>{`then,`}</p>
    <p>{`12:00 AMRAP:`}</p>
    <p>{`\\
12-Goblet Squats (53/35)(RX+ 70/53)`}</p>
    <p>{`12-KBS’s`}</p>
    <p>{`12-KB Forward Lunges, alternating legs (6/leg)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      